export const Colors = {
  primary: {
    900: "#96782c",
    800: "#af8c33",
    700: "#c8a03a",
    600: "#e1b442",
    500: "#fac849",
    400: "#fbce5b",
    300: "#fbd36d",
    200: "#fcd980",
    100: "#fcde92",
  },
};

// export const socketURL = "ws://116.203.191.150:5013/ws_web_charging";
// export const socketURL =
//   "wss://pj-cloud-frontend-proxy-yebllxeflq-lz.a.run.app/ws_web_charging";

// export const API_URL = "http://116.203.191.150:5013/api/initiate_charging";

export const API_URL =
  "https://pj-cloud-frontend-proxy-yebllxeflq-lz.a.run.app/initiate_charging";

export const request_url = `https://pj-url-resolver-yebllxeflq-ey.a.run.app/api`;

// const url_vercel =
//   "https://ecom-seven-pi.vercel.app/api/redirect-url?url=" + url;

// export const API_URL = "https://devappservice.powerjames.com/initiate_charging";

// export const address = {
//   YDFTOK01: "40915115F9AC",
//   YDFTOK02: "40915115CB8C",
//   YDFTOK03: "409151165314",
// };

export const address = {
  YDFTOK01: "40915115F9AC",
  YDFTOK02: "40915115CB8C",
  YDFTOK03: "409151165314",
};
