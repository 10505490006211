import axios from "axios";
import { useMemo } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import swr from "swr";
import { base64encode } from "Utils/base64";
import { API_URL, request_url } from "Utils/setting";
import { TWallbox } from "./../Context/SocketContext";
const useApi = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const { data } = swr("/all/wallbox", async () => await getWallbox());
  const wallbox = data
    ? data
    : {
        YDFTOK01: "",
        YDFTOK02: "",
        YDFTOK03: "",
      };

  const urlRedirectChecker = async (url: string) => {
    const URL = request_url + `/resolveUrl?url=${url}`;

    return (
      await toast.promise(
        axios.get(URL),
        {
          loading: "Validating QR Code",
          success: () => {
            return "QR Code Validated";
          },
          error: (err) => {
            return "Invalid URL";
          },
        },
        {
          duration: 50000,
          loading: {
            duration: 10000,
          },
          success: {
            duration: 5000,
            icon: "👍",
          },
        }
      )
    ).data.url;
  };

  const getWallbox = async () => {
    return (await axios.get<TWallbox>(request_url + "/wallboxes")).data;
  };

  const updateWallbox = async (data: TWallbox) => {
    return (
      await toast.promise(
        axios.put<TWallbox>(request_url + "/wallboxes", data),
        {
          loading: "Updating Wallbox",
          success: () => {
            return "Wallbox Updated";
          },
          error: (err) => {
            return "Something went wrong";
          },
        }
      )
    ).data;
  };

  const charging = async (command: boolean) => {
    const device_token = query.get("device_token");
    const car_token = query.get("car_token");
    const data = {
      device_token: base64encode(
        wallbox[device_token as "YDFTOK01" | "YDFTOK02" | "YDFTOK03"]
      ),
      car_token: car_token,
      command,
    };

    return await toast.promise(
      axios.post(API_URL, data),
      {
        loading: command ? "Starting charging" : "Stopping charging",
        success: () => {
          return command ? "Starting charging" : "Stopping charging";
        },
        error: (err) => {
          return err?.response?.data?.message || "Something went wrong";
        },
      },
      {
        duration: 10000,
        loading: {
          duration: 10000,
        },
        success: {
          duration: 10000,
          icon: "👍",
        },
      }
    );
  };

  return { charging, urlRedirectChecker, getWallbox, updateWallbox };
};

export default useApi;
