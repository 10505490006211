import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { detect } from "detect-browser";
import { useNavigate } from "react-router-dom";
const PermissionDeniedModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const isChrome = detect()?.name === "chrome";
  const navigage = useNavigate();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay maxW={"100vw"} />
        <ModalContent maxW={"90vw"}>
          <ModalHeader>
            {isChrome ? "Erlaubnis erteilen" : "Erlaubnis verweigert"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {isChrome
                ? "Bitte erlaube Zugriff auf die Kamera in den Einstellungen"
                : "Bitte Seite neu laden u den Zugriff auf die Kamera erlauben"}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex w="full" alignItems="center" justifyContent={"space-between"}>
              <Link
                textDecoration={"underline"}
                color="blue.800"
                onClick={() => navigage("/help")}
              >
                Wie geht das?
              </Link>

              <Button colorScheme="blue" onClick={onClose}>
                Erledigt
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PermissionDeniedModal;
