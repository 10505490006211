/* eslint-disable react-hooks/exhaustive-deps */
import { useSocket } from "Context/SocketContext";
import useApi from "Hooks/useApi";
import Layout from "Layout";
import { useEffect } from "react";
import swr from "swr";
import WallboxScanner from "../Components/WallboxScanner";

const WallboxScannerPage = () => {
  const { getWallbox } = useApi();
  const { setWallbox } = useSocket();

  const { data } = swr("/all/wallbox", async () => await getWallbox());

  useEffect(() => {
    if (data) {
      setWallbox(data);
    }
  }, [data]);

  return (
    <Layout title="Willkommen" subTitle="Wähle die Wallbox aus">
      <WallboxScanner />
    </Layout>
  );
};

export default WallboxScannerPage;
