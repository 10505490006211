import { Center, Spinner, VStack } from "@chakra-ui/react";
import useApi from "Hooks/useApi";
import Layout from "Layout";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const StartPage = () => {
  const { charging } = useApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const device_token = query.get("device_token");
  const car_token = query.get("car_token");
  return (
    <Layout title="Bereit zum Laden" subTitle="Drücke den Startknopf">
      <VStack h="60dvh" w="full" alignItems={"center"} justifyContent="center">
        <Center
          // borderRadius={"full"}
          // border="8px solid #FF9153"
          // w="12em"
          // h="12em"
          userSelect="none"
          className={`charge-start-button-container${
            loading ? "" : "-inActive"
          } `}
        >
          <Center
            className={`charge-start-button${loading ? "" : "-inActive"}`}
            fontSize="1.2em"
            color="#6B706E"
            fontWeight="bold"
            userSelect="none"
            onClick={() => {
              setLoading(true);
              charging(true)
                .then((res) => {
                  if (res.status === 200) {
                    setTimeout(() => {
                      setLoading(false);
                      navigate(
                        `/stop?car_token=${car_token}&device_token=${device_token}`
                      );
                    }, 10000);
                  }
                })
                .catch(() => {
                  setLoading(false);
                });
            }}
          >
            {loading ? <Spinner color="white" /> : "Start"}
          </Center>
        </Center>
      </VStack>
    </Layout>
  );
};

export default StartPage;
