import { VStack } from "@chakra-ui/layout";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import PermissionDeniedModal from "./PermissionDeniedModal";

// import { QrReader } from 'react-qr-reader';

const QrCodeScanner: React.FC<{
  isWallbox: boolean;
  onScan: (d: string) => void;
}> = ({ isWallbox, onScan }) => {
  const [isCameraDenied, setIsCameraDenied] = useState<boolean>();
  const [lastQrCode, setLastQrCode] = useState("");
  useEffect(() => {
    if (!navigator.mediaDevices) {
      alert("getUserMedia() not supported.");
    } else {
      navigator.mediaDevices
        ?.getUserMedia({ video: { facingMode: "environment" } })
        .then(() => setIsCameraDenied(false))
        .catch(() => setIsCameraDenied(true));
    }
  }, []);

  return (
    <VStack
      justifyContent="center"
      border={isWallbox ? "1px solid #0D72DD" : "1px solid #EF5945"}
      minWidth={"calc(100% - 2%)"}
      maxW={"calc(100% - 2%)"}
      w="max-content"
      flex={1}
      maxHeight="35dvh"
      // minHeight={'max-content'}
      overflow="hidden"
      borderRadius={"md"}
    >
      <PermissionDeniedModal
        isOpen={isCameraDenied || false}
        onClose={() => setIsCameraDenied(false)}
      />

      {!isCameraDenied && (
        <QrScanner
          // onDecode={(res) => {
          //   if (!isQr && isWallbox) {
          //     router.push(`/?qr=car&token=${res}`);
          //   } else {
          //     alert(res);
          //   }
          // }}
          scanDelay={1000}
          onDecode={(res) => {
            //https://qrcc.me/rptj5zgnf3b1
            if (res !== lastQrCode) {
              setLastQrCode(res);
              const domain = res.split("qrcc.me")[1];
              if (!domain) {
                toast.error("Please scan a valid QR Code");
                return;
              }
              onScan(res);
            }
          }}
          onError={(error) => console.log("error = ", error)}
        />
      )}
    </VStack>
  );
};

export default QrCodeScanner;
