import { Image } from "@chakra-ui/image";
import { HStack, Text } from "@chakra-ui/layout";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const path = {
    "/": `Schritt ${query.get("car_token") ? 2 : 1}/3`,
    "/car": `Schritt ${query.get("device_token") ? 2 : 1}/3`,
    "/start": "Schritt 3/3",
    "/stop": "Schritt 3/3",
  };
  return (
    <HStack minW={"full"} justifyContent={"space-between"}>
      <Text color={"#A5A5A5"} fontSize="lg" fontWeight={700}>
        {path[location.pathname as keyof typeof path]}
      </Text>
      <Image
        src={process.env.PUBLIC_URL + "/logo.svg"}
        alt="logo"
        w={"2em"}
        h="2em"
      />
    </HStack>
  );
};

export default Navbar;
