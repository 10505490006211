import { Heading, Image, Link, Text, VStack } from "@chakra-ui/react";

import Image1 from "../Images/1.png";
import Image2 from "../Images/2.png";
import Image3 from "../Images/3.png";
import Image4 from "../Images/4.png";
import Logo from "../Images/logo.svg";

export default function Help() {
  return (
    <VStack p="2em" w="100vw" h="100dvh" alignItems={"center"} gap=".5em">
      <Heading>Hilfeseite</Heading>
      <Text>
        Hey! Vielen Dank, dass Du die Ressourcen unseres Planeten durch
        Carsharing und elektrisches Fahren schützt.
      </Text>
      <Heading size={"sm"} fontWeight="bold" fontFamily={"Poppins"}>
        Kamera zum Scannen funktioniert nicht? Erteile bitte die Berechtigung.
      </Heading>
      <Image src={Image1} />
      <Image src={Image2} />
      <Image src={Image3} />
      <Heading textAlign={"start"} w="full" size={"sm"}>
        Zuletzt Webseite aktualisieren:
      </Heading>
      <Image src={Image4} />
      <Heading size={"sm"} w="full" textAlign={"center"}>
        Andere Probleme mit dem Ladevorgang?
      </Heading>
      <VStack w="full" py="1.5em">
        <Heading>POWERJames Support</Heading>
        <Link size={"md"} textDecoration="underline">
          +49 (0) 621 121 874 01
        </Link>
        <Link size={"md"} textDecoration="underline">
          support@powerjames.com
        </Link>
        <Heading size={"sm"}>Mon - Fri</Heading>
        <Heading size={"sm"}>9:00 - 17:00</Heading>
      </VStack>
      <Image w={"60vw"} src={Logo} />
      <VStack py="1.5em" w="full" alignItems={"center"}>
        <Heading size={"sm"} textAlign="center">
          POWERJames GmbH
        </Heading>
        <Heading size={"sm"} textAlign="center">
          Daniel-Seizinger-Weg 2 68307 Mannheim Deutschland
        </Heading>
        <Heading size={"sm"} textAlign="center">
          www.powerjames.com
        </Heading>
      </VStack>
    </VStack>
  );
}
