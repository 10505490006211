import { Center, Flex, Text } from "@chakra-ui/react";
import { useSocket } from "Context/SocketContext";
import useApi from "Hooks/useApi";
import { useMemo } from "react";
import { ImQrcode } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import QrCodeScanner from "./QrCodeScanner";

const CarScanner: React.FC = () => {
  const { isValidHeight } = useSocket();

  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const { urlRedirectChecker } = useApi();

  const handleScan = (e: string) => {
    console.log("data = ", e);
    urlRedirectChecker(e).then((url: string) => {
      const token = url.split("token=")[1];
      if (query.get("device_token")) {
        navigate(
          `/start?car_token=${token}&device_token=${query.get("device_token")}`
        );
      } else {
        navigate(`/?&car_token=${token}`);
      }
    });
  };

  return (
    <Flex
      justifyContent={"space-around"}
      flex={1}
      w={"full"}
      h="100%"
      flexDir={"column"}
      gap="1em"
    >
      <Flex
        alignItems={"center"}
        justifyContent="flex-start"
        w="full"
        gap="1em"
        h="max-content"
      >
        <Center border={"2px solid #096FE0"} p=".5em" borderRadius={"md"}>
          <ImQrcode size={40} />
        </Center>
        <Text color={"#414443"} fontSize={isValidHeight ? ".85em" : ".75em"}>
          Um den Ladevorgang zu starten, scanne den Blauen QR Code im
          Handschuhfach
        </Text>
      </Flex>

      <QrCodeScanner isWallbox={false} onScan={handleScan} />
    </Flex>
  );
};

export default CarScanner;
