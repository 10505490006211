import { Button, Input, VStack } from "@chakra-ui/react";
import { TWallbox } from "Context/SocketContext";
import useApi from "Hooks/useApi";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import swr, { useSWRConfig } from "swr";
const WallboxConfiguration = () => {
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);
  const { getWallbox, updateWallbox } = useApi();

  const { data } = swr("/all/wallbox", async () => await getWallbox());
  console.log("data setting = ", data);
  const { mutate } = useSWRConfig();
  const [wallbox, setWallbox] = useState<TWallbox>({
    YDFTOK01: "",
    YDFTOK02: "",
    YDFTOK03: "",
  });

  const navigate = useNavigate();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setWallbox({ ...wallbox, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!data) return;
    setWallbox(data);
  }, [data]);

  // useLayoutEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const user = localStorage.getItem("user");
  //     if (user && user === "1") {
  //       setVerified(true);
  //     }
  //   }
  // }, []);

  return (
    <VStack
      alignItems={"center"}
      justifyContent="center"
      px="3em"
      w="full"
      minH={"100dvh"}
    >
      {verified ? (
        <VStack>
          <Input
            w="80dvw"
            onChange={handleChange}
            name={"YDFTOK01"}
            value={wallbox.YDFTOK01}
          />
          <Input
            w="80dvw"
            onChange={handleChange}
            name={"YDFTOK02"}
            value={wallbox.YDFTOK02}
          />
          <Input
            w="80dvw"
            onChange={handleChange}
            name={"YDFTOK03"}
            value={wallbox.YDFTOK03}
          />
          <Button
            w="full"
            colorScheme={"green"}
            onClick={async () => {
              if (
                wallbox.YDFTOK01 === "" ||
                wallbox.YDFTOK02 === "" ||
                wallbox.YDFTOK03 === ""
              ) {
                toast.error("Please fill in all fields");
                return;
              }
              await updateWallbox(wallbox).then(() => {
                navigate("/");
                mutate("/all/wallbox");
              });
            }}
          >
            Submit
          </Button>
        </VStack>
      ) : (
        <PasswordProtection
          password={password}
          setPassword={setPassword}
          verifyPassword={(e: boolean) => setVerified(e)}
        />
      )}
    </VStack>
  );
};

const PasswordProtection = ({
  password,
  setPassword,
  verifyPassword,
}: {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  verifyPassword: (v: boolean) => void;
}) => {
  return (
    <form
      style={{
        width: "100%",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        const isPasswordVerified = password === "jonas12";
        if (!isPasswordVerified) {
          toast.error("Wrong password");
          return;
        }
        verifyPassword(true);
      }}
    >
      <VStack w="full" height={"full"}>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button w="full" colorScheme={"green"} type="submit">
          Verify
        </Button>
      </VStack>
    </form>
  );
};

export default WallboxConfiguration;
