import RoutesList from "./Routes/index";

function App() {
  return (
    <>
      <RoutesList />
    </>
  );
}

export default App;
