import { Button, Center, Flex, Text, VStack } from "@chakra-ui/react";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const Layout: React.FC<{
  title: string;
  subTitle: string;
  children: React.ReactNode;
}> = ({ title, subTitle, children }) => {
  const [isValidHeight, setIsValidHeight] = useState<boolean>(true);
  const navigage = useNavigate();
  useLayoutEffect(() => {
    if (typeof window === "undefined") return;
    setIsValidHeight(window.innerHeight >= 700);
  }, []);

  return (
    <Flex
      minW={"100vw"}
      maxW="100vw"
      minH={"100dvh"}
      maxH="100dvh"
      flexDir={"column"}
      alignItems="center"
      p="1em 1.5em"
      justifyContent={"space-between"}
      gap="1em"
      overflow={"hidden"}
    >
      <Navbar />
      <Flex flexDir={"column"} w="full" alignItems={"flex-start"}>
        <Text
          color={"#414443"}
          fontSize={isValidHeight ? "4xl" : "2xl"}
          fontWeight={"bold"}
        >
          {title}
        </Text>
        <Text
          color={"#414443"}
          fontSize={isValidHeight ? "2xl" : "xl"}
          fontWeight={"semibold"}
        >
          {subTitle}
        </Text>
      </Flex>
      <VStack w="full" h="80%" alignItems="center">
        {children}
      </VStack>
      <Center w="full" h="8dvh">
        <Button
          variant={"link"}
          color="#414443"
          userSelect={"none"}
          _focus={{ outline: "none", background: "none" }}
          _active={{ outline: "none", background: "none" }}
          colorScheme={"cyan"}
          minW="max-content"
          onClick={() => navigage("/help")}
        >
          Hilfe benötigt?
        </Button>
      </Center>
    </Flex>
  );
};

export default Layout;
