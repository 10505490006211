import { createContext, useContext, useLayoutEffect, useState } from "react";

export type TWallbox = Record<"YDFTOK01" | "YDFTOK02" | "YDFTOK03", string>;

interface SocketContextProps {
  isValidHeight: boolean;
  wallbox: TWallbox;
  setWallbox: React.Dispatch<React.SetStateAction<TWallbox>>;
}

const SocketContext = createContext({} as SocketContextProps);

const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [isValidHeight, setIsValidHeight] = useState<boolean>(true);
  const [wallbox, setWallbox] = useState<TWallbox>({
    YDFTOK01: "",
    YDFTOK02: "",
    YDFTOK03: "",
  });

  useLayoutEffect(() => {
    if (typeof window === "undefined") return;
    setIsValidHeight(window.innerHeight >= 700);
    const wallboxFromLocalStorage = JSON.parse(
      localStorage.getItem("wallbox") as string
    );
    if (wallboxFromLocalStorage) {
      setWallbox(wallboxFromLocalStorage);
    }
  }, []);

  return (
    <SocketContext.Provider
      value={{
        isValidHeight,
        wallbox,
        setWallbox,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export { SocketProvider, useSocket };
