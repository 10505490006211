import { Center, Spinner, VStack } from "@chakra-ui/react";
import useApi from "Hooks/useApi";
import Layout from "Layout";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getTokens } from "Utils/getTokens";
const StopPage = () => {
  const { charging } = useApi();
  const [loading, setLoading] = useState(false);
  const [animation, setAnimation] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const { car_token, device_token } = getTokens(query);
  return (
    <Layout title="Bereit zum Laden" subTitle="Drücke den Startknopf">
      <VStack h="60dvh" w="full" alignItems={"center"} justifyContent="center">
        <Center
          userSelect="none"
          className={
            loading ? "charging-container" : "charge-start-button-container"
          }
        >
          <Center
            onClick={() => {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                navigate(
                  `/start?car_token=${car_token}&device_token=${device_token}`
                );
              }, 10000);

              charging(false);
            }}
            className={loading ? "charging" : "charge-start-button"}
            // className={`charging${animation ? "" : "-inActive"}`}
          >
            {loading ? <Spinner color="white" /> : "Stop"}
          </Center>
        </Center>
      </VStack>
    </Layout>
  );
};

export default StopPage;
