import CarScannerPage from "Pages/CarPage";
import Help from "Pages/HelpCamera";
import StartPage from "Pages/Start";
import StopPage from "Pages/Stop";
import WallboxConfiguration from "Pages/WallboxConfiguration";
import WallboxScannerPage from "Pages/WallboxPage";
import { Route, Routes } from "react-router-dom";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/" element={<WallboxScannerPage />} />
      <Route path="/car" element={<CarScannerPage />} />
      <Route path="/setting" element={<WallboxConfiguration />} />
      <Route path="/start" element={<StartPage />} />
      <Route path="/stop" element={<StopPage />} />
      <Route path="/help" element={<Help />} />
    </Routes>
  );
};

export default RoutesList;
