import { Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { useSocket } from "Context/SocketContext";
import useApi from "Hooks/useApi";
import { useMemo } from "react";
import { ImQrcode } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import QrCodeScanner from "./QrCodeScanner";

const WallboxScanner = () => {
  const { isValidHeight } = useSocket();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const { urlRedirectChecker } = useApi();
  const handleScan = (e: string) => {
    urlRedirectChecker(e).then((url: string) => {
      console.log("url = ", url);
      const token = url.split("token=")[1];
      if (query.get("car_token")) {
        navigate(
          `/start?device_token=${token}&car_token=${query.get("car_token")}`
        );
      } else {
        navigate(`/car?&device_token=${token}`);
      }
    });
  };

  return (
    <Flex
      justifyContent={"space-around"}
      flex={1}
      w={"full"}
      h="100%"
      flexDir={"column"}
      gap="1em"
    >
      <Flex
        alignItems={"center"}
        justifyContent="flex-start"
        w="full"
        gap="1em"
        h="max-content"
      >
        <Center border={"2px solid #096FE0"} p=".5em" borderRadius={"md"}>
          <ImQrcode size={40} />
        </Center>
        <Text color={"#414443"} fontSize={isValidHeight ? ".85em" : ".75em"}>
          Um den Ladevorgang zu starten, scanne den orangenen QR Code auf der
          Walllbox
        </Text>
      </Flex>
      <VStack gap=".5em" w="full" alignItems={"flex-start"}>
        <Text color="#414443" fontSize={isValidHeight ? ".85em" : ".75em"}>
          oder wähle die Nummer der Wallbox{" "}
        </Text>
        <HStack w={"full"} justifyContent="space-between">
          {Array.from({ length: 3 }).map((_, index) => (
            <SelectOptionBox
              key={index}
              value={index + 1}
              isValidHeight={isValidHeight}
            />
          ))}
        </HStack>
      </VStack>
      <QrCodeScanner isWallbox={true} onScan={handleScan} />
    </Flex>
  );
};

const SelectOptionBox = ({
  value,
  isValidHeight,
}: {
  value: number;
  isValidHeight: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const handleClick = () => {
    if (query.get("car_token")) {
      navigate(
        `/start?device_token=YDFTOK0${value}&car_token=${query.get(
          "car_token"
        )}`
      );
    } else {
      navigate("/car?device_token=YDFTOK0" + value);
    }
  };

  return (
    <Center
      w="28%"
      h={isValidHeight ? "4em" : "3em"}
      borderRadius={"md"}
      bg="#096FE0"
      onClick={handleClick}
    >
      <Text fontSize={"1.2em"} color="white" fontWeight={"semibold"}>
        {value}
      </Text>
    </Center>
  );
};

export default WallboxScanner;
