import CarScanner from "../Components/CarScanner";
import Layout from "../Layout";

const CarScannerPage = () => {
  return (
    <Layout title="Willkommen" subTitle="Blauen QR Code scannen">
      <CarScanner />
    </Layout>
  );
};

export default CarScannerPage;
